const { random } = require("tinycolor2");

AFRAME.registerComponent('heating-celsius-animation', {
    schema: {
      intensity: { default: 1.0 }
    },
    init: function () {
      // TODO make it stop
      this.createCelsiusDigitParticles(this.el);
    },
    createCelsiusDigitParticles: function (heatPipe) {
      const digits = ['25'];
      const particleCount = 5;
      const dissolveDuration = 2000;
      const delayBetweenParticles = 1000;
  
      digits.forEach(digit => {
        for (let i = 0; i < particleCount; i++) {
          setTimeout(() => {
            const posX = - Math.random() * 5 - 0.15;
            const posY = 0.5;
            const posZ = 1.7;
  
            const digitEntity = document.createElement("a-text");
            digitEntity.setAttribute("value", `${digit}° C`);
            digitEntity.setAttribute("color", "#ff6666");
            digitEntity.setAttribute("scale", "1 1 1");
            digitEntity.setAttribute("font", "custom-msdf.json");
            digitEntity.setAttribute("negate", "false");
            digitEntity.setAttribute("position", { x: posX, y: posY, z: posZ });
            digitEntity.setAttribute("class", "heatingDigit");
  
            heatPipe.appendChild(digitEntity);
  
            // Wait for the entity to load before animating
            digitEntity.addEventListener('loaded', () => {
              this.animateCelsiusDigitParticle(digitEntity, dissolveDuration);
            });
          }, i * delayBetweenParticles);
        }
      });
    },
    animateCelsiusDigitParticle: function (digitEntity, duration) {
      const randomNumber = Math.random() * 3;
      digitEntity.setAttribute('animation', {
        property: 'position',
        dur: duration,
        easing: 'linear',
        loop: true,
        to: `${digitEntity.getAttribute('position').x} ${digitEntity.getAttribute('position').y + randomNumber} ${digitEntity.getAttribute('position').z}`
      });
    },
    tick: function (time) {
      const intensity = this.data.intensity;
      const children = this.el.children;
      if (intensity == 0) {
        for (let i = 0, len = children.length; i < len; ++i) {
          children[i].setAttribute("visible", false);
        }
      } else {
        for (let i = 0, len = children.length; i < len; ++i) {
          children[i].setAttribute("visible", true);
        }
      }
    }
    }
  );